<template>
  <div>
    <b-modal
      id="modal-barang"
      size="lg"
      title="'Tambah Data Barang"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
      @shown="getData"
    >
      <b-form-group label-cols-md="3">
        <template v-slot:label> Barang <span class="text-danger">*</span> </template>
        <b-form-select
          :state="checkIfValid('ms_barang_id')"
          :options="option_barang"
          v-model="$v.is_data.ms_barang_id.$model"
          @change="getSatuanBarang($event), createBatch()"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Satuan Beli <span class="text-danger">*</span> </template>
        <b-form-select
          :disabled="!is_data.ms_barang_id"
          :state="checkIfValid('ms_satuan_beli_id')"
          :options="option_satuan_beli"
          v-model="$v.is_data.ms_satuan_beli_id.$model"
        ></b-form-select>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Sumber Dana <span class="text-danger">*</span> </template>
        <b-form-select
          :state="checkIfValid('ms_sumber_dana_id')"
          :options="option_sumber_dana"
          v-model="$v.is_data.ms_sumber_dana_id.$model"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tanggal Kadaluarsa <span class="text-danger">*</span>
        </template>
        <date-picker
          style="width: 100%"
          :state="checkIfValid('tanggal_kadaluarsa')"
          type="date"
          format="DD-MM-YYYY"
          placeholder="Tanggal Kadaluarsa"
          v-model="$v.is_data.tanggal_kadaluarsa.$model"
        ></date-picker>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Tahun Anggaran <span class="text-danger">*</span>
        </template>
        <date-picker
          style="width: 100%"
          :state="checkIfValid('tahun_anggaran')"
          type="year"
          format="YYYY"
          value-type="YYYY"
          placeholder="Pilih Tahun"
          v-model="$v.is_data.tahun_anggaran.$model"
        ></date-picker>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Kode Batch <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('kode_batch')"
          v-model="$v.is_data.kode_batch.$model"
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Jumlah Penerimaan <span class="text-danger">*</span>
        </template>
        <b-form-input
          :state="checkIfValid('qty_pembelian')"
          v-model="$v.is_data.qty_pembelian.$model"
          type="number"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Harga Satuan <span class="text-danger">*</span>
        </template>
        <b-form-input
          :state="checkIfValid('harga_satuan_barang')"
          v-model="$v.is_data.harga_satuan_barang.$model"
          type="number"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Harga Perolehan <span class="text-danger">*</span>
        </template>
        <b-form-input disabled v-model="total_harga"></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-barang')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="tambahBarang()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalBarang",
  // props: ["fileName", "option_barang", "option_gudang", "option_petugas"],
  data() {
    return {
      // tanggal_pembelian, no_bast, total_harga_pembelian, ms_supplier_id, ms_gudang_id, bulk_barang
      is_data: {
        ms_barang_id: null,
        kode_batch: null,
        qty_pembelian: null,
        harga_satuan_barang: null,
        total_harga_sub_pembelian: null,
        ms_satuan_beli_id: null, // ms_satuan_barang
        ms_sumber_dana_id: null,
        tanggal_kadaluarsa: null,
        tahun_anggaran: null,
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      locale: "id-ID",
      option_barang: [],
      option_satuan_beli: [],
      option_sumber_dana: [],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    total_harga() {
      let x = this.is_data.qty_pembelian * this.is_data.harga_satuan_barang;
      return x;
    },
  },
  watch: {
    total_harga(newVal) {
      this.is_data.total_harga_sub_pembelian = newVal;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      ms_barang_id: { required },
      kode_batch: { required },
      qty_pembelian: { required },
      harga_satuan_barang: { required },
      // total_harga_sub_pembelian: { required },
      ms_satuan_beli_id: {},
      ms_sumber_dana_id: { required },
      tanggal_kadaluarsa: { required },
      tahun_anggaran: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log("register", vm.is_data);
      // tanggal_pembelian, no_bast, total_harga_pembelian, ms_supplier_id, ms_gudang_id, bulk_barang
      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-barang");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tambahBarang() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      const isNullish = Object.values(vm.is_data).every((value) => {
        if (value === null) {
          return true;
        }

        return false;
      });
      if (!isNullish) {
        vm.$v.$reset();
        let x = this.option_barang.find((o) => o.value === this.is_data.ms_barang_id);
        vm.is_data["ms_barang"] = x;
        vm.$emit("tambahBarang", vm.is_data);
        vm.$bvModal.hide("modal-barang");
        vm.resetModal();
      }

      vm.busy = false;
      vm.button = "Simpan";
    },

    async getData() {
      let vm = this;
      // ! ms_barang
      let option_barang = await vm.$axios.post("/ms_barang/list");
      vm.option_barang =
        option_barang.data.status == 200
          ? option_barang.data.data.map((x) => {
              return { value: x.ms_barang_id, text: x.nama_barang };
            })
          : [];
          console.log(vm.option_barang, 'ini barang');
      let option_sumber_dana = await vm.$axios.post("/ms_sumber_dana/list");
      vm.option_sumber_dana =
        option_sumber_dana.data.status == 200
          ? option_sumber_dana.data.data.map((x) => {
              return { value: x.ms_sumber_dana_id, text: x.nama_sumber_dana };
            })
          : [];
    },
    async getSatuanBarang(ms_barang_id) {
      let vm = this;
      // ! ms_satuan_barang
      let x = { ms_barang_id };
      let option_satuan_beli = await vm.$axios.post("/pool_satuan_barang/list", x);
      vm.option_satuan_beli =
        option_satuan_beli.data.status == 200
          ? option_satuan_beli.data.data.map((x) => {
              return { value: x.ms_satuan_barang_id, text: x.nama_satuan };
            })
          : [];
    },
    createBatch() {
      let namBar = this.option_barang.find((o) => o.value == this.is_data.ms_barang_id).text;
      let x = namBar[0] + namBar[1] + this.$moment().format("ddMMYYhhmmss");
      this.is_data.kode_batch = x;
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        ms_barang_id: null,
        kode_batch: null,
        qty_pembelian: null,
        harga_satuan_barang: null,
        total_harga_sub_pembelian: null,
        ms_satuan_beli_id: null, // ms_satuan_barang
        ms_sumber_dana_id: null,
        tanggal_kadaluarsa: null,
        tahun_anggaran: null,
      };
    },
  },
};
</script>
