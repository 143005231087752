<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ pageName }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-tambah-pembelian
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      @click="editDataPembelian(item.item)"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-hapus-pembelian
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-tambah-pembelian
      :fileName="file_name"
      :option_supplier="option_supplier"
      :option_gudang="option_gudang"
      :option_petugas="option_petugas"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit-pembelian
      :fileName="file_name"
      :data_edit="is_data"
      :option_supplier="option_supplier"
      :option_gudang="option_gudang"
      :option_petugas="option_petugas"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-hapus-pembelian
      :fileName="file_name"
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalTambahPembelian from "./modalTambahPembelian.vue";
import ModalEditPembelian from "./modalEditPembelian.vue";
import ModalHapusPembelian from "./modalHapusPembelian.vue";

export default {
  components: {
    ModalTambahPembelian,
    ModalEditPembelian,
    ModalHapusPembelian,
  },
  name: "pembelian",
  data() {
    return {
      file_name: "pembelian",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "no_pembelian",
          label: "Pembelian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "no_bast",
          label: "BAST",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_supplier",
          label: "Supplier",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_harga_pembelian_format",
          label: "Total Pembelian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggal_format",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama",
          label: "Petugas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      option_supplier: [],
      option_gudang: [],
      option_petugas: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let res = await vm.$axios.post(`/${vm.file_name}/list`);
      console.log(vm.file_name, res);

      vm.items = res.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.id = x.user_id;
        x.tanggal_format = this.$moment(x.tanggal_pembelian).format('DD MMMM YYYY')
        x.total_harga_pembelian_format = this.$numberFormat(x.total_harga_pembelian, 'IDR')
      }

      vm.totalRows = vm.items.length;
      vm.tableBusy = false;

      //! ms_supplier
      let option_supplier = await vm.$axios.post("/ms_supplier/list");
      vm.option_supplier =
        option_supplier.data.status == 200
          ? option_supplier.data.data.map((x) => {
              return { value: x.ms_supplier_id, text: x.nama_supplier };
            })
          : [];
    
      //! ms_gudang
      let option_gudang = await vm.$axios.post("/ms_gudang/list");
      vm.option_gudang =
        option_gudang.data.status == 200
          ? option_gudang.data.data.map((x) => {
              return { value: x.ms_gudang_id, text: x.nama_gudang };
            })
          : [];
    
      //! petugas
      let option_petugas = await vm.$axios.post("/user/list");
      vm.option_petugas =
        option_petugas.data.status == 200
          ? option_petugas.data.data.map((x) => {
              return { ...x, value: x.user_id, text: x.nama };
            })
          : [];
    },
    async editDataPembelian(val) {
      let x = await this.$axios.post("/pembelian/details_barang_pembelian", { pembelian_id: val.pembelian_id })
      this.is_data = x.data.status == 200 ? x.data.data.map((o) =>{ return  { ...o, tanggal_pembelian: new Date(o.tanggal_pembelian) } })[0] : null
      if (this.is_data) {
        console.log("asd");
        this.$bvModal.show('modal-edit-pembelian')
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
