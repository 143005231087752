<template>
  <div>
    <b-modal
      id="modal-barang-edit"
      size="lg"
      centered
      title="Update Data Barang"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
      @shown="getData(), getSatuanBarang()"
      v-if="is_data"
    >
      <b-form @submit.prevent>
        <!-- sub_pembelian_id, pembelian_id, qty_pembelian, total_harga_sub_pembelian, kode_batch, tanggal_kadaluarsa, ms_barang_id, ms_gudang_id, ms_satuan_beli_id, ms_sumber_dana_id, harga_satuan_barang, total_harga_pembelian -->
        <!-- <b-form-group label-cols-md="3">
        <template v-slot:label> Pembelian <span class="text-danger">*</span> </template>
        <b-form-input
          readonly
          v-model="$v.is_data.pembelian_id.$model"
          type="text"
          :state="checkIfValid('pembelian_id')"
        ></b-form-input>
      </b-form-group> -->
        <!-- <b-form-group label-cols-md="3">
        <template v-slot:label>
          Sub Pembelian <span class="text-danger">*</span>
        </template>
        <b-form-input
          readonly
          v-model="is_data.sub_pembelian_id"
          type="text"
        ></b-form-input>
      </b-form-group> -->
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Barang <span class="text-danger">*</span>
          </template>
          <b-form-select
            v-model="$v.is_data.ms_barang_id.$model"
            :options="option_barang"
            :state="checkIfValid('ms_barang_id')"
            @change="tambahKeyEdit('ms_barang_id')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Satuan Beli <span class="text-danger">*</span>
          </template>
          <b-form-select
            :disabled="!is_data.ms_barang_id"
            :state="checkIfValid('ms_satuan_beli_id')"
            :options="option_satuan_beli"
            v-model="$v.is_data.ms_satuan_beli_id.$model"
            @change="tambahKeyEdit('ms_satuan_beli_id')"
          ></b-form-select>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Sumber Dana <span class="text-danger">*</span>
          </template>
          <b-form-select
            :state="checkIfValid('ms_sumber_dana_id')"
            :options="option_sumber_dana"
            v-model="$v.is_data.ms_sumber_dana_id.$model"
            @change="tambahKeyEdit('ms_sumber_dana_id')"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Kadaluarsa <span class="text-danger">*</span>
          </template>
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggal_kadaluarsa')"
            type="date"
            format="DD-MM-YYYY"
            placeholder="Tanggal Kadaluarsa"
            v-model="$v.is_data.tanggal_kadaluarsa.$model"
            @change="tambahKeyEdit('tanggal_kadaluarsa')"
          ></date-picker>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tahun Anggaran <span class="text-danger">*</span>
          </template>
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tahun_anggaran')"
            type="year"
            format="YYYY"
            value-type="YYYY"
            placeholder="Pilih Tahun"
            v-model="$v.is_data.tahun_anggaran.$model"
            @change="tambahKeyEdit('tahun_anggaran')"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Batch <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.kode_batch.$model"
            :state="checkIfValid('kode_batch')"
            type="text"
            debounce="1000"
            @change="tambahKeyEdit('kode_batch')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Qty Pembelian <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.qty_pembelian.$model"
            :state="checkIfValid('qty_pembelian')"
            type="number"
            debounce="1000"
            @change="
              calculateJumlahTotalHargaSubPembelian(), tambahKeyEdit('qty_pembelian')
            "
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Harga Satuan Barang <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="$v.is_data.harga_satuan_barang.$model"
            :state="checkIfValid('harga_satuan_barang')"
            type="number"
            debounce="1000"
            @change="
              calculateJumlahTotalHargaSubPembelian(), tambahKeyEdit('harga_satuan_barang')
            "
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Total Sub Harga <span class="text-danger">*</span>
          </template>
          <b-form-input
            readonly
            v-model="$v.is_data.total_harga_sub_pembelian.$model"
            :state="checkIfValid('total_harga_sub_pembelian')"
            type="number"
            debounce="1000"
            @change="tambahKeyEdit('total_harga_sub_pembelian')"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-barang-edit')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalBarangEdit",
  props: ["data_edit", "option_supplier", "option_gudang", "option_petugas"],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      // sub_pembelian_id, pembelian_id, qty_pembelian, total_harga_sub_pembelian, kode_batch, tanggal_kadaluarsa, ms_barang_id, ms_gudang_id, ms_satuan_beli_id, ms_sumber_dana_id, harga_satuan_barang, total_harga_pembelian
      is_data: {
        sub_pembelian_id: "",
        pembelian_id: "",
        qty_pembelian: "",
        total_harga_sub_pembelian: "",
        kode_batch: "",
        tanggal_kadaluarsa: "",
        ms_barang_id: "",
        ms_gudang_id: "",
        ms_satuan_beli_id: "",
        ms_sumber_dana_id: "",
        harga_satuan_barang: "",
        total_harga_pembelian: "",
        tahun_anggaran: "",
      },
      is_data_update: {},
      file_name: "pembelian",
      locale: "id-ID",
      option_barang: [],
      option_satuan_beli: [],
      option_sumber_dana: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      this.is_data.tanggal_kadaluarsa = new Date(this.is_data.tanggal_kadaluarsa);
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      sub_pembelian_id: {},
      pembelian_id: {},
      qty_pembelian: {},
      total_harga_sub_pembelian: {},
      kode_batch: {},
      tanggal_kadaluarsa: {},
      ms_barang_id: {},
      ms_gudang_id: {},
      ms_satuan_beli_id: {},
      ms_sumber_dana_id: {},
      harga_satuan_barang: {},
      total_harga_pembelian: {},
      tahun_anggaran: {},
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log(vm.is_data_update);
      vm.$axios
        .post(`/${vm.file_name}/update_barang`, vm.is_data_update)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-barang-edit");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    calculateJumlahTotalHargaSubPembelian() {
      const qty = parseFloat(this.is_data.qty_pembelian);
      const hargaSatuan = parseFloat(this.is_data.harga_satuan_barang);
      const total_harga_pembelian_awal = parseFloat(this.is_data.total_harga_pembelian)
      const total_harga_pembelian_akhir = parseFloat(this.is_data.total_harga_pembelian) - parseFloat(this.is_data.total_harga_sub_pembelian)

      console.log();
      if (!isNaN(qty) && !isNaN(hargaSatuan)) {
        this.is_data.total_harga_sub_pembelian = 0;
        this.is_data.total_harga_sub_pembelian = qty * hargaSatuan;
        this.is_data.total_harga_pembelian = parseFloat(this.is_data.total_harga_sub_pembelian) + parseFloat(total_harga_pembelian_akhir)
        console.log(this.is_data.total_harga_pembelian, total_harga_pembelian_akhir);
      }
    },
    tambahKeyEdit(key) {
      console.log("jalan", key);
      if (!("pembelian_id" in this.is_data_update) && !this.is_data_update.pembelian_id) {
        this.is_data_update.pembelian_id = this.is_data.pembelian_id;
      }
      if (!("sub_pembelian_id" in this.is_data_update) && !this.is_data_update.sub_pembelian_id) {
        this.is_data_update.sub_pembelian_id = this.is_data.sub_pembelian_id;
      }
      if (key == 'qty_pembelian' || key == 'harga_satuan_barang') {
        this.is_data_update.total_harga_sub_pembelian = this.is_data.total_harga_sub_pembelian
        this.is_data_update.total_harga_pembelian = this.is_data.total_harga_pembelian
        this.is_data_update[key] = this.is_data[key];
      } else {
        this.is_data_update.qty_pembelian = this.is_data.qty_pembelian;
        this.is_data_update.harga_satuan_barang = this.is_data.harga_satuan_barang;
      }
      console.log(this.is_data_update);
    },

    async getData() {
      let vm = this;
      // ! ms_barang
      let option_barang = await vm.$axios.post("/ms_barang/list");
      vm.option_barang =
        option_barang.data.status == 200
          ? option_barang.data.data.map((x) => {
              return { value: x.ms_barang_id, text: x.nama_barang };
            })
          : [];
      let option_sumber_dana = await vm.$axios.post("/ms_sumber_dana/list");
      vm.option_sumber_dana =
        option_sumber_dana.data.status == 200
          ? option_sumber_dana.data.data.map((x) => {
              return { value: x.ms_sumber_dana_id, text: x.nama_sumber_dana };
            })
          : [];
    },

    async getSatuanBarang(ms_barang_id) {
      let vm = this;
      // ! ms_satuan_barang
      let x = { ms_barang_id };
      let option_satuan_beli = await vm.$axios.post("/pool_satuan_barang/list", x);
      vm.option_satuan_beli =
        option_satuan_beli.data.status == 200
          ? option_satuan_beli.data.data.map((x) => {
              return { value: x.ms_satuan_barang_id, text: x.nama_satuan };
            })
          : [];
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        sub_pembelian_id: "",
        pembelian_id: "",
        qty_pembelian: "",
        total_harga_sub_pembelian: "",
        kode_batch: "",
        tanggal_kadaluarsa: "",
        ms_barang_id: "",
        ms_gudang_id: "",
        ms_satuan_beli_id: "",
        ms_sumber_dana_id: "",
        harga_satuan_barang: "",
        total_harga_pembelian: "",
        tahun_anggaran: "",
      };
      this.is_data_update = {};
    },
  },
};
</script>
