<template>
  <div>
    <b-modal
      id="modal-tambah-pembelian"
      size="xl"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="5">
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Tanggal <span class="text-danger">*</span>
              </template>
              <date-picker
                style="width: 100%"
                id="tanggal_pembelian"
                :state="checkIfValid('tanggal_pembelian')"
                v-model="$v.is_data.tanggal_pembelian.$model"
                format="DD-MM-YYYY"
                @change="createBAST"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col cols="7">
            <b-form-group label-cols-md="4">
              <template v-slot:label> BAST <span class="text-danger">*</span> </template>
              <b-form-input
                :state="checkIfValid('no_bast')"
                type="text"
                v-model="$v.is_data.no_bast.$model"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="5">
            <b-form-group label-cols-md="3">
              <template v-slot:label>
                Supplier <span class="text-danger">*</span>
              </template>
              <b-form-select
                :state="checkIfValid('ms_supplier_id')"
                :options="option_supplier"
                v-model="$v.is_data.ms_supplier_id.$model"
                @change="createBAST"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="7">
            <b-form-group label-cols-md="4">
              <template v-slot:label>
                Gudang <span class="text-danger">*</span>
              </template>
              <b-form-select
                :state="checkIfValid('ms_gudang_id')"
                :options="option_gudang"
                v-model="$v.is_data.ms_gudang_id.$model"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7" offset="5">
            <b-form-group label-cols-md="4">
              <template v-slot:label>
                <strong> Total Harga Pembelian </strong><span class="text-danger">*</span>
              </template>
              <b-form-input
                disabled
                :state="checkIfValid('total_harga_pembelian')"
                type="number"
                v-model="$v.is_data.total_harga_pembelian.$model"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              responsive
              show-empty
              small
              @filtered="onFiltered"
              bordered
              striped
              hover
              :busy="tableBusy"
            >
              <!-- <template #empty>
                <div class="text-center">
                  <b-button
                    variant="info"
                    v-c-tooltip.hover.click="'Tambah barang'"
                    v-b-modal.modal-barang
                  >
                    <CIcon name="cil-plus" />Tambah Barang
                  </b-button>
                </div>
              </template> -->
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(actions)="item">
                <b-button
                  variant="danger"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Hapus Data'"
                  @click="deleteListBarang(item.item, item.index)"
                  ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="warning"
              class="text-white"
              v-c-tooltip.hover.click="'Tambah barang'"
              v-b-modal.modal-barang
            >
              <CIcon name="cil-plus" /> BARANG
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-tambah-pembelian')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>

    <modal-barang @tambahBarang="tambahBarang($event)" />
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ModalBarang from "./modalBarang.vue";

export default {
  components: {
    ModalBarang,
  },
  name: "modalTambahPembelian",
  props: ["fileName", "option_supplier", "option_gudang", "option_petugas"],
  data() {
    return {
      // tanggal_pembelian, no_bast, total_harga_pembelian, ms_supplier_id, ms_gudang_id, bulk_barang
      is_data: {
        tanggal_pembelian: new Date(),
        no_bast: null,
        total_harga_pembelian: null,
        ms_supplier_id: null,
        ms_gudang_id: null,
        petugas_id: null, // user_id
        bulk_barang: [],
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      locale: "id-ID",

      fields: [
        {
          key: "no",
          label: "No.",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "ms_barang.text",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_batch",
          label: "Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_pembelian",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "harga_satuan_barang",
          label: "Harga Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_harga_sub_pembelian",
          label: "Total Sub Pembelian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-1 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      tanggal_pembelian: { required },
      no_bast: { required },
      total_harga_pembelian: { required },
      ms_supplier_id: { required },
      ms_gudang_id: { required },
      // petugas_id: { required },
      // bulk_barang: { required },
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      // tanggal_pembelian, no_bast, total_harga_pembelian, ms_supplier_id, ms_gudang_id, bulk_barang
      console.log("simpan", vm.is_data);
      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + this.pageName.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-tambah-pembelian");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        tanggal_pembelian: null,
        no_bast: null,
        total_harga_pembelian: null,
        ms_supplier_id: null,
        ms_gudang_id: null,
        petugas_id: null,
        bulk_barang: [],
      };
      this.items = []
    },
    tambahBarang(ev) {
      // console.log("modal input", ev);
      ev.no = this.items.length + 1
      this.items.push(ev);
      this.is_data.total_harga_pembelian = 0
      for (let i = 0; i < this.items.length; i++) {
        const el = this.items[i];
        this.is_data.total_harga_pembelian += el.total_harga_sub_pembelian
      }
      this.is_data.bulk_barang = this.items

    },
    deleteListBarang(val, idx) {
      console.log(val.total_harga_sub_pembelian);
      this.items.splice(idx, 1)
      this.is_data.total_harga_pembelian -= val.total_harga_sub_pembelian
    },
    createBAST(){
      let x = 'BA' + this.$moment(this.is_data.tanggal_pembelian).format("DDMMYYhhmmss")
      this.is_data.no_bast = x
    }
  },
};
</script>
