<template>
  <div>
    <b-modal
      id="modal-edit-pembelian"
      size="xl"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-overlay :show="showOverlay" rounded="sm">
        <div v-if="is_data">
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Pembelian <span class="text-danger">*</span>
            </template>
            <date-picker
              style="width: 100%"
              id="tanggal_pembelian"
              :state="checkIfValid('tanggal_pembelian')"
              v-model="$v.is_data.tanggal_pembelian.$model"
              format="DD-MM-YYYY"
              @change="addKey('tanggal_pembelian')"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label> BAST <span class="text-danger">*</span> </template>
            <b-form-input
              v-model="$v.is_data.no_bast.$model"
              type="text"
              :state="checkIfValid('no_bast')"
              @change="addKey('no_bast')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Total Harga Pembelian <span class="text-danger">*</span>
            </template>
            <b-form-input
              readonly
              v-model="$v.is_data.total_harga_pembelian.$model"
              :state="checkIfValid('total_harga_pembelian')"
              @change="addKey('total_harga_pembelian')"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Supplier <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.is_data.ms_supplier_id.$model"
              :options="option_supplier"
              :state="checkIfValid('ms_supplier_id')"
              @change="addKey('ms_supplier_id')"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Gudang <span class="text-danger">*</span> </template>
            <b-form-select
              v-model="$v.is_data.ms_gudang_id.$model"
              :options="option_gudang"
              :state="checkIfValid('ms_gudang_id')"
              @change="addKey('ms_gudang_id')"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Petugas <span class="text-danger">*</span> </template>
            <b-form-select
              disabled
              v-model="$v.is_data.petugas_id.$model"
              :options="option_petugas"
              :state="checkIfValid('petugas_id')"
              @change="addKey('petugas_id')"
            ></b-form-select>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Total Harga <span class="text-danger">*</span>
            </template>
            <b-form-input
              readonly
              v-model="$v.is_data.total_harga_pembelian.$model"
              type="number"
              :state="checkIfValid('total_harga_pembelian')"
              @change="addKey('total_harga_pembelian')"
            ></b-form-input>
          </b-form-group>

          <b-table
            :items="is_data.data_barang"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(no)="item">
              {{ item.index + 1 }}
            </template>
            <template #cell(actions)="item">
              <b-button
                variant="warning"
                class="mr-1"
                v-c-tooltip.hover.click="'Edit Data'"
                @click="openModalBarangEdit(item.item)"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
              >
              <b-button
                variant="danger"
                class="mr-1"
                v-c-tooltip.hover.click="'Hapus Data'"
                @click="openModalBarangDelete(item.item)"
                ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
              >
            </template>
          </b-table>
        </div>

        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit-pembelian')">
            Batal
          </b-button>
          <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
            button
          }}</b-button>
        </template>
      </b-overlay>
    </b-modal>

    <modal-barang-edit
      :fileName="'pembelian'"
      :data_edit="is_data_barang"
      :option_gudang="option_gudang"
      @alertFromChild="triggerAlert($event), getDetailBarang()"
    ></modal-barang-edit>
    <modal-barang-delete
      :fileName="'pembelian'"
      :data_delete="is_data_barang"
      @alertFromChild="triggerAlert($event), getDetailBarang()"
    ></modal-barang-delete>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'
import ModalBarangEdit from "./modalBarangEdit.vue";
import ModalBarangDelete from "./modalBarangDelete.vue";

export default {
  name: "modalEditPembelian",
  props: ["fileName", "data_edit", "option_supplier", "option_gudang", "option_petugas"],
  components: {
    // Multiselect,
    ModalBarangEdit,
    ModalBarangDelete,
  },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      is_data_barang: null,
      is_edited: {},
      file_name: this.fileName,
      locale: "id-ID",

      fields: [
        "no",
        {
          key: "nama_barang",
          label: "Barang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_batch",
          label: "Batch",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "qty_pembelian",
          label: "Jumlah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "harga_satuan_barang",
          label: "Harga Satuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "total_harga_sub_pembelian",
          label: "Total Sub Pembelian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
      showOverlay: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      // for (let i = 0; i < this.option_specialist.length; i++) {
      //   const el = this.option_specialist[i];
      //   if (el.value === this.is_data.role) {
      //     this.is_data.role = el
      //   }
      // }
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      tanggal_pembelian: { required },
      no_bast: { required },
      total_harga_pembelian: { required },
      ms_supplier_id: { required },
      ms_gudang_id: { required },
      petugas_id: { required },
      total_harga_pembelian: { required },
      // bulk_barang: { required },
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getDetailBarang() {
      let vm = this
      vm.showOverlay = true
      let x = await this.$axios.post("/pembelian/details_barang_pembelian", {
        pembelian_id: this.is_data.pembelian_id,
      });
      console.log("x", x);
      this.is_data =
        x.data.status == 200
          ? x.data.data.map((o) => {
              return { ...o, tanggal_pembelian: new Date(o.tanggal_pembelian) };
            })[0]
          : null;

        vm.showOverlay = false
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log(this.is_edited);
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_edited)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-pembelian");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    openModalBarangEdit(val) {
      this.is_data_barang = val;
      this.is_data_barang.tanggal_kadaluarsa = new Date(
        this.is_data_barang.tanggal_kadaluarsa
      );
      this.is_data_barang.total_harga_pembelian = this.is_data.total_harga_pembelian;
      console.log("data_barang", this.is_data_barang);
      this.$bvModal.show("modal-barang-edit");
    },
    openModalBarangDelete(val) {
      this.is_data_barang = val;
      this.$bvModal.show("modal-barang-delete");
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        tanggal_pembelian: null,
        no_bast: null,
        total_harga_pembelian: null,
        ms_supplier_id: null,
        ms_gudang_id: null,
        petugas_id: null,
        total_harga_pembelian: null,
      };
      this.is_edited = {};
      this.is_data_barang = null;
    },
    addKey(val) {
      if (!("id" in this.is_edited)) {
        this.is_edited.id = this.is_data[`${this.file_name}_id`];
      }
      this.is_edited[val] = this.is_data[val];
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
