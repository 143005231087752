<template>
  <div> 
    <b-modal
      id="modal-hapus-pembelian"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="danger"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menghapus data ini ?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-hapus-pembelian')">
          Tidak
        </b-button>
        <b-button variant="primary" @click="hapus()" :disabled="busy">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import _ from "lodash";
  export default {
    name: "modalHapusPembelian",
    props: ["fileName", "data_delete"],
    data() {
      return {
        busy: false,
        button: "Hapus",
        file_name: this.fileName
      };
    },
    computed: {
      pageName() {
        let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
        let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
        return capitalizedWords.join(" ");
      },
    },
    template: `<button @click="console.log("aku")">Pencet</button>`,
    methods: {
      hapus() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.$axios
          .post(`/${vm.file_name}/delete_pembelian`, { pembelian_id: vm.data_delete[`${vm.file_name}_id`] })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Hapus";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGHAPUS " + this.pageName.toUpperCase(),
                showing: true,
              });
              this.$bvModal.hide("modal-hapus-pembelian");
            } else {
              vm.button = "Hapus";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Hapus";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
    },
  };
  </script>
  